import { Container, Typography } from "@material-ui/core";
import Seo from "gatsby-plugin-wpgraphql-seo";
import React from "react";
import { useTranslation } from "react-i18next";
import { PostTypeInProject } from "../../../config/wordPressConfig";
import { PageLayout } from "../../layouts/pageLayout/PageLayout";

const NotFoundPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PageLayout
      centerContent={true}
      currentPostId=""
      postType={PostTypeInProject.WpPage}
    >
      <Seo title="404: Not found" />

      <Container className="noMargin textCenter">
        <Typography variant="h1">{t("404.title")}</Typography>
      </Container>
    </PageLayout>
  );
};

export default NotFoundPage;
